@import url("https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,200;0,300;0,400;0,500;1,100;1,200;1,300;1,400;1,500&display=swap");

:root {
  --font-primary: "Kanit", sans-serif;
  --font-secondary: "Kanit", sans-serif;
  --bg-header: #272b30;
  --bg-footer: #1c1e22;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  font-size: 62.5%;
  height: 100%;
}

body {
  height: 100%;
  background-color: #2a2e33;
  color: #aaa;
  font-family: var(--font-primary);
  font-size: 1.6rem;
  line-height: 1.5;
}

#root {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

ul {
  list-style: none;
}

img {
  display: block;
}

a {
  text-decoration: none;
}

.container {
  width: min(100%, 1200px);
  padding: 0 2rem;
  margin: 0 auto;
}

.text-muted {
  color: #7a8288;
}

.title {
  font-family: var(--font-secondary);
  font-size: 2.7rem;
  font-weight: 600;
  text-shadow: -1px -1px 0 rgb(0 0 0 / 30%);
}

.subtitle {
  font-size: 1.5rem;
  margin-top: 7px;
  color: #b6b6b6;
}

.text-center {
  text-align: center;
}

@media screen and (max-width: 576px) {
  .title {
    font-size: 2rem;
  }
}
