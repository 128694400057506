.lead {
  color: #007bff;
}

.filter {
  display: flex;
  align-items: center;
  margin: 2rem 0 3rem;
}

.filter label {
  display: inline-block;
  margin-right: 1rem;
  cursor: pointer;
}

.filter select {
  display: block;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 0.6rem 1.2rem;
  border-radius: 5px;
  font-family: inherit;
  font-size: 1.5rem;
  background: #32383e;
  color: #ccc;
  box-shadow: 0 0.4rem 1.2rem rgba(0, 0, 0, 0.12);
}

.filter option {
  font-size: 1.5rem;
}
