.spinner {
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  border: 4px solid #007bff;
  border-bottom-color: transparent;
  animation: spin 700ms linear infinite;
  margin: 0 auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
