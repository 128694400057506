@import url(https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,200;0,300;0,400;0,500;1,100;1,200;1,300;1,400;1,500&display=swap);
:root {
  --font-primary: "Kanit", sans-serif;
  --font-secondary: "Kanit", sans-serif;
  --bg-header: #272b30;
  --bg-footer: #1c1e22;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  font-size: 62.5%;
  height: 100%;
}

body {
  height: 100%;
  background-color: #2a2e33;
  color: #aaa;
  font-family: "Kanit", sans-serif;
  font-family: var(--font-primary);
  font-size: 1.6rem;
  line-height: 1.5;
}

#root {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

ul {
  list-style: none;
}

img {
  display: block;
}

a {
  text-decoration: none;
}

.container {
  width: min(100%, 1200px);
  padding: 0 2rem;
  margin: 0 auto;
}

.text-muted {
  color: #7a8288;
}

.title {
  font-family: "Kanit", sans-serif;
  font-family: var(--font-secondary);
  font-size: 2.7rem;
  font-weight: 600;
  text-shadow: -1px -1px 0 rgb(0 0 0 / 30%);
}

.subtitle {
  font-size: 1.5rem;
  margin-top: 7px;
  color: #b6b6b6;
}

.text-center {
  text-align: center;
}

@media screen and (max-width: 576px) {
  .title {
    font-size: 2rem;
  }
}

.Home_form__3mkrt {
  max-width: 40rem;
  margin: 3rem auto;
}

.Home_form__3mkrt label {
  display: block;
  position: relative;
  min-height: 5rem;
  border-radius: 5px;
  overflow-x: hidden;
}

.Home_input__3derM {
  -webkit-appearance: none;
          appearance: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 1rem;
  padding-left: 4rem;
  border: none;
  background-color: #1c1e22;
  color: #e7e7e7;
  caret-color: #fff;
  font-family: inherit;
  font-size: 1.5rem;
}

.Home_input__3derM:focus {
  outline: none;
  background-color: #181a1d;
}

.Home_input__3derM::-webkit-input-placeholder {
  color: #929292;
}

.Home_input__3derM:-ms-input-placeholder {
  color: #929292;
}

.Home_input__3derM::placeholder {
  color: #929292;
}

.Home_search_icon__1dfOs {
  position: absolute;
  left: 1.5rem;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  color: #929292;
  font-size: 2rem;
  z-index: 2;
  pointer-events: none;
}

.Home_games_content__ZvEdR {
  margin-top: 5rem;
}

.GameList_grid__17XYg {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 2rem;
  gap: 2rem;
  align-items: start;
}

@media screen and (min-width: 600px) {
  .GameList_grid__17XYg {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (min-width: 992px) {
  .GameList_grid__17XYg {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (min-width: 1200px) {
  .GameList_grid__17XYg {
    grid-template-columns: repeat(4, 1fr);
  }
}

.GameItem_card__1V50_ {
  background: #32383e;
  border-radius: 5px;
  overflow-x: hidden;
  box-shadow: 0 0.3rem 1rem rgba(0, 0, 0, 0.1);
}

.GameItem_card_header__33Cj5 {
  display: block;
  position: relative;
  height: 160px;
  overflow: hidden;
}

@supports (aspect-ratio: 16 / 10) {
  .GameItem_card_header__33Cj5 {
    aspect-ratio: 16 / 10;
    height: auto;
    height: initial;
  }
}

.GameItem_thumbnail__2VhdS {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.GameItem_card_body__xPvFa {
  padding: 2rem;
}

.GameItem_title__34iNk {
  color: #aaaaaa;
  font-size: 2rem;
  font-weight: 600;
}

.GameItem_title__34iNk:hover {
  color: #cfcfcf;
  text-decoration: underline;
}

.GameItem_description__SRz4P {
  margin-top: 1rem;
  font-size: 1.5rem;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
}

.GameItem_card_footer__6IQmr {
  margin-top: 3rem;
  display: flex;
  justify-content: space-between;
}

.GameItem_card_footer__6IQmr div {
  display: flex;
  align-items: center;
}

.GameItem_badge__2lUcS {
  display: inline-block;
  font-size: 1.2rem;
  padding: 2px 8px;
  border-radius: 8px;
  background-color: #7a8288;
  color: #000000;
  font-weight: 700;
  margin-right: 1rem;
}

.GameItem_platform_icon__3U9bk {
  display: inline-block;
  color: #7a8288;
  font-size: 2rem;
}

.GameItem_btn__1j3Rk {
  -webkit-appearance: none;
          appearance: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  cursor: pointer;
}

.GameItem_heart_icon__1bUN- {
  color: #aaaaaa;
  font-size: 2rem;
}

.GameItem_heart_icon__1bUN-:hover {
  color: #fff;
}

.Spinner_spinner__2k4-R {
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  border: 4px solid #007bff;
  border-bottom-color: transparent;
  -webkit-animation: Spinner_spin__39K9K 700ms linear infinite;
          animation: Spinner_spin__39K9K 700ms linear infinite;
  margin: 0 auto;
}

@-webkit-keyframes Spinner_spin__39K9K {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes Spinner_spin__39K9K {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.Popular_lead__2ZlIt {
  color: #007bff;
}

.Popular_filter__3KLPS {
  display: flex;
  align-items: center;
  margin: 2rem 0 3rem;
}

.Popular_filter__3KLPS label {
  display: inline-block;
  margin-right: 1rem;
  cursor: pointer;
}

.Popular_filter__3KLPS select {
  display: block;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 0.6rem 1.2rem;
  border-radius: 5px;
  font-family: inherit;
  font-size: 1.5rem;
  background: #32383e;
  color: #ccc;
  box-shadow: 0 0.4rem 1.2rem rgba(0, 0, 0, 0.12);
}

.Popular_filter__3KLPS option {
  font-size: 1.5rem;
}

.Favorites_favorite__3dWRT h1 {
  margin-bottom: 2rem;
}

.Details_game_details__1hKIN {
  margin-top: 2rem;
}

.Details_article__ymSBd {
  display: flow-root;
}

.Details_thumbnail__csSp3 {
  float: left;
  margin-right: 2rem;
  margin-bottom: 2rem;
  box-shadow: 0 6px 1.2rem rgba(0, 0, 0, 0.15);
  border-radius: 5% 5% 5% 5%;
}

.Details_title__OugfD {
  font-size: 2.8rem;
}

.Details_article__ymSBd h3 {
  font-weight: 600;
  font-size: 2.2rem;
  margin-top: 5rem;
}

.Details_info_list__UvUG_ {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 2rem;
  gap: 2rem;
  margin-top: 2rem;
}

.Details_platform__1pqsn {
  display: flex;
  align-items: center;
}

.Details_platform_icon__2zB_q {
  display: inline-block;
  margin-right: 1rem;
  font-size: 2rem;
}

.Details_screenshot_grid__2bTca {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
  grid-gap: 2rem;
  gap: 2rem;
  margin-top: 2rem;
}

.Details_screenshot_grid__2bTca img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
  box-shadow: 0 4px 1.2rem rgba(0, 0, 0, 0.1);
}

.Details_btn_favorite__2Chs3 {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: none;
  cursor: pointer;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  background-color: #000000;
  position: fixed;
  right: 2rem;
  bottom: 3rem;
  z-index: 999;
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.25);
}

.Details_heart_icon__1gsbK {
  display: inline-block;
  color: #fff;
  font-size: 2rem;
}

@media screen and (max-width: 768px) {
  .Details_thumbnail__csSp3 {
    float: none;
    margin: 0;
    width: 100%;
    aspect-ratio: 16 / 9;
    object-fit: cover;
    margin-bottom: 2rem;
  }

  .Details_info_list__UvUG_ {
    grid-template-columns: 1fr;
    grid-gap: 1.5rem;
    gap: 1.5rem;
  }
}

@media screen and (max-width: 576px) {
  .Details_game_details__1hKIN {
    margin-top: 0;
  }

  .Details_title__OugfD {
    font-size: 2.4rem;
  }

  .Details_article__ymSBd h3 {
    font-size: 2rem;
  }

  .Details_btn_favorite__2Chs3 {
    right: 1rem;
    bottom: 8rem;
  }
}

.NotFound_not_found__AxFLK {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.NotFound_link__SPICF {
  display: inline-block;
  background-color: #ccc;
  color: #000;
  padding: 1.2rem 2rem;
  border-radius: 5rem;
  margin-top: 3rem;
}

.Header_header__2otSh {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 80px;
  background-color: var(--bg-header);
  padding: 1rem 0;
  display: flex;
  align-items: center;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  z-index: 1000;
}

.Header_header__2otSh .Header_container__3UMvB {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Header_logo__2yYRj {
  height: 40px;
}

.Header_navigation__36OsL ul {
  display: flex;
  align-items: baseline;
}

.Header_navigation__36OsL li {
  margin-left: 4rem;
}

.Header_navigation__36OsL a {
  color: rgba(255, 255, 255, 0.8);
  font-family: var(--font-secondary);
}

.Header_navigation__36OsL a:hover {
  color: #fff;
}

h1 span #Header_cx__HwEaS {
  color: rgb(227, 136, 32);
}
h1 span #Header_rx__EqgGb {
  color: rgb(35, 227, 32);
}

@media screen and (max-width: 576px) {
  .Header_header__2otSh {
    display: none;
  }
}

.Footer_footer__28pE7 {
  margin-top: auto;
  background: var(--bg-footer);
  padding: 2rem 0;
  text-align: center;
}

.Footer_created__3g-n7 {
  margin-top: 1rem;
}

.Footer_heart__21Rh7 {
  display: inline-block;
  height: 50px;
  margin-bottom: -3px;
  color: #ff0000;
}

@media screen and (max-width: 576px) {
  .Footer_footer__28pE7 {
    display: none;
  }
}

.BottomBar_navigation__3r595 {
  background: var(--bg-header);
  box-shadow: 0 0.4rem 1.5rem rgba(0, 0, 0, 0.2);
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
}

.BottomBar_navigation__3r595 ul {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  padding: 1rem 2rem;
}

.BottomBar_navigation__3r595 a {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: var(--font-secondary);
  color: rgba(255, 255, 255, 0.6);
}

.BottomBar_navigation__3r595 a:hover,
.BottomBar_navigation__3r595 a.BottomBar_active__2RQAT {
  color: #fff;
}

.BottomBar_navigation__3r595 span {
  display: inline-block;
  font-size: 1.4rem;
  margin-top: 3px;
}

.BottomBar_icon__1fN0J {
  font-size: 2rem;
}

@media screen and (min-width: 576px) {
  .BottomBar_navigation__3r595 {
    display: none;
  }
}

.Layout_main_content__EnlhF {
  margin: 12rem auto 10rem;
  height: 100%;
}

@media screen and (max-width: 576px) {
  .Layout_main_content__EnlhF {
    margin: 0 auto;
    padding: 3rem 2rem 13rem;
  }
}

