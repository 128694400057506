.not_found {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.link {
  display: inline-block;
  background-color: #ccc;
  color: #000;
  padding: 1.2rem 2rem;
  border-radius: 5rem;
  margin-top: 3rem;
}
